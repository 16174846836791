/* eslint-disable quote-props, object-shorthand */
/*
*   NOTE
*   This file is auto generated.
*   Any manual changes will be overwritten!
*/
import en from './en.json';
import pt from './pt.json';
import zhCN from './zh-CN.json';
import zhTW from './zh-TW.json';

const locales = {
    'en': en,
    'pt': pt,
    'zh-CN': zhCN,
    'zh-TW': zhTW,
};

export default locales;
