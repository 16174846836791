import { AccountPayload } from '../accounts/types';
import mapper, { Item } from '../../mapper';
import { mapAccount } from '../accounts';
import songwhipApi from '../songwhipApi';
import { ItemTypes } from '~/types';
import { AddItemErrorCodes, OwnedByAccount } from '../types';

export type AddUserPageError =
  | {
      status: 'error';
      code: AddItemErrorCodes.PRIMARY_OWNER_PERMISSION_REQUIRED;
      data: {
        primaryOwner: OwnedByAccount;
        accounts: AccountPayload[];
      };
    }
  | {
      status: 'error';
      code: AddItemErrorCodes.ACCOUNT_SELECTION_REQUIRED;
      data: {
        accounts: AccountPayload[];
      };
    }
  | {
      status: 'error';
      code: AddItemErrorCodes.ITEM_NOT_IN_CATALOG;
    }
  | {
      status: 'error';
      code: AddItemErrorCodes.LABEL_MISMATCH;
    };

export type AddUserPageResult =
  | {
      status: 'success';
      code?: undefined;
      account: AccountPayload;
      item: Item;
    }
  | {
      status: 'success';
      code: 'USER_ALREADY_OWNER';
      account: AccountPayload;
      item: Item;
    };

const addUserPageApi = async ({
  userId,
  ...body
}: {
  userId: number;
  itemType: ItemTypes;
  itemId: number;
  dryRun?: boolean;
}) => {
  const {
    json: { data },
  } = await songwhipApi<AddUserPageResult & { item: Item }>(
    `users/${userId}/pages`,
    {
      method: 'POST',
      body,
      showToastOnError: false,
      logoutOnForbidden: false,
    }
  );

  return {
    item: mapper(data.item),
    account: mapAccount(data.account),
    code: data.code,
  };
};

export default addUserPageApi;
